












































































































































































































































import Vue from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { intersection, difference, uniqBy } from 'lodash'

import { tr } from '@/locale'
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue'
import ConfirmSetCategoryDialog from './dialogs/ConfirmSetCategoryDialog.vue'
import EditTrackerDialog from './dialogs/EditTrackerDialog.vue'
import InfoDialog from './dialogs/InfoDialog.vue'
import api from '../Api'
import { formatSize } from '@/filters'
import { DialogType, TorrentFilter, ConfigPayload, DialogConfig, SnackBarConfig } from '@/store/types'
import Component from 'vue-class-component'
import { Torrent, Category, Tag } from '@/types'
import { Watch } from 'vue-property-decorator'

function getStateInfo(state: string) {
  let icon;
  switch (state) {
    case 'metaDL':
    case 'allocating':
    case 'downloading':
    case 'forcedDL':
      icon = {
        icon: 'download',
        color: 'info',
      };
      break;
    case 'uploading':
    case 'forcedUP':
      icon = {
        icon: 'upload',
        color: 'info',
      };
      break;
    case 'stalledDL':
      icon = {
        icon: 'download',
        color: null,
      };
      break;
    case 'stalledUP':
      icon = {
        icon: 'upload',
        color: null,
      };
      break;
    case 'pausedDL':
      icon = {
        icon: 'pause',
        color: 'warning',
      };
      break;
    case 'pausedUP':
      icon = {
        icon: 'check',
        color: null,
      };
      break;
    case 'queuedDL':
    case 'queuedUP':
      icon = {
        icon: 'timer-sand',
        color: 'info',
      };
      break;
    case 'checkingDL':
    case 'checkingUP':
    case 'queuedForChecking':
    case 'checkingResumeData':
    case 'moving':
      icon = {
        icon: 'backup-restore',
        color: 'info',
      };
      break;
    case 'error':
    case 'unknown':
    case 'missingFiles':
      icon = {
        icon: 'alert',
        color: 'error',
      };
      break;
    default:
      throw Error('Unknown state');
  }

  return icon;
}

@Component({
  components: {
    ConfirmDeleteDialog,
    ConfirmSetCategoryDialog,
    EditTrackerDialog,
    InfoDialog,
  },
  computed: {
    ...mapGetters([
      'isDataReady',
      'allTorrents',
      'allTags',
      'allCategories',
      'torrentGroupByCategory',
      'torrentGroupByTag',
      'torrentGroupBySite',
      'torrentGroupByState',
    ]),
    ...mapState({
      filter(state, getters) {
        return getters.config.filter;
      },
    }),
  },
  filters: {
    formatNetworkSpeed(speed: number) {
      if (speed === 0) {
        return null;
      }

      return `${formatSize(speed)}/s`;
    },
    stateIcon(state: string) {
      const item = getStateInfo(state);
      return `mdi-${item.icon}`;
    },
    stateColor(state: string, isProgress?: boolean, isSeqDL?: boolean) {
      const item = getStateInfo(state);
      if (!isProgress) {
        return item.color;
      }
      if (isSeqDL) {
        return '#e33371' // icon.color.secondary;
      }

      return item.color || '#0008';
    },
  },
  methods: {
    ...mapMutations([
      'updateConfig',
      'showSnackBar',
    ]),
    ...mapActions([
      'asyncShowDialog',
    ]),
  },
})
export default class Torrents extends Vue {
  readonly headers = [
    { text: tr('name'), value: 'name' },
    { text: tr('size'), value: 'size' },
    { text: tr('progress'), value: 'progress' },
    { text: tr('status'), value: 'state' },
    { text: tr('seeds'), value: 'num_complete' },
    { text: tr('peers'), value: 'num_incomplete' },
    { text: tr('dl_speed'), value: 'dlspeed' },
    { text: tr('up_speed'), value: 'upspeed' },
    { text: tr('eta'), value: 'eta' },
    { text: tr('ratio'), value: 'ratio' },
    { text: tr('added_on'), value: 'added_on' },
  ]

  readonly footerProps = {
    'items-per-page-options': [10, 20, 50, -1],
  }

  selectedRows: Torrent[] = []
  toDelete: Torrent[] = []
  toSetCategory: Torrent[] = []
  categoryToSet: string | null = null
  toShowInfo: Torrent[] = []
  toEditTracker: Torrent[] = []
  infoTab = null
  pageOptions: any = null

  isDataReady!: boolean
  allTorrents!: Torrent[]
  allCategories!: Category[]
  allTags!: Tag[]
  torrentGroupByCategory!: {[category: string]: Torrent[]}
  torrentGroupByTag!: {[tag: string]: Torrent[]}
  torrentGroupBySite!: {[site: string]: Torrent[]}
  torrentGroupByState!: {[state: string]: Torrent[]}
  filter!: TorrentFilter

  updateConfig!: (_: ConfigPayload) => void
  showSnackBar!: (_: SnackBarConfig) => void
  asyncShowDialog!: (_: DialogConfig) => Promise<string | undefined>

  get loading() {
    return !this.isDataReady;
  }
  get hasSelected() {
    return !!this.selectedRows.length;
  }
  get selectedHashes() {
    return this.selectedRows.map(r => r.hash);
  }

  get torrents() {
    if (!this.isDataReady) {
      return [];
    }

    let list = this.allTorrents;
    if (this.filter.site !== null) {
      list = intersection(list, this.torrentGroupBySite[this.filter.site]);
    }
    if (this.filter.category !== null) {
      list = intersection(list, this.torrentGroupByCategory[this.filter.category]);
    }
    if (this.filter.tag !== null) {
      list = intersection(list, this.torrentGroupByTag[this.filter.tag]);
    }
    if (this.filter.state !== null) {
      list = intersection(list, this.torrentGroupByState[this.filter.state]);
    }
    if (this.filter.query) {
      const q = this.filter.query.toLowerCase();

      list = list.filter(t => {
        return t.name.toLowerCase().includes(q) ||
          t.tracker.toLowerCase().includes(q) ||
          t.category.toLowerCase().includes(q);
      });
    }

    return list;
  }

  get hasSelectedAll() {
    return this.hasSelected && this.selectedRows.length
      === Math.min(this.torrents.length, this.pageOptions.rowsPerPage);
  }

  getProgressColorClass(progress: number) {
    const color = (progress >= 0.5 || (this as any).$vuetify.theme.dark)
      ? 'white' : 'black';
    return `${color}--text`;
  }

  created() {
    this.pageOptions = this.$store.getters.config.pageOptions;
  }

  confirmDelete() {
    this.toDelete = this.selectedRows;
  }

  showInfo(row?: any) {
    this.toShowInfo = row ? [row] : this.selectedRows;
  }

  async resumeTorrents() {
    await api.resumeTorrents(this.selectedHashes);
  }

  async forceStartTorrents() {
    await api.setForceStartTorrents(this.selectedHashes);
  }

  async toggleSequentialTorrents() {
    await api.toggleSequentialTorrents(this.selectedHashes);
  }
  async pauseTorrents() {
    await api.pauseTorrents(this.selectedHashes);
  }

  async reannounceTorrents() {
    if (!this.hasSelected) {
      this.selectedRows = this.allTorrents;
    }

    await api.reannounceTorrents(this.selectedHashes);

    this.showSnackBar({text: tr('label.reannounced')});
  }

  async recheckTorrents() {
    const v = await this.asyncShowDialog({
      title: tr('title.recheck_torrents'),
      text: tr('dialog.recheck_torrents.msg'),
      type: DialogType.OkCancel,
    });

    if (!v) {
      return;
    }
    await api.recheckTorrents(this.selectedHashes);

    this.showSnackBar({text: tr('label.rechecking')});
  }

  async setTorrentLocation() {
    const savePaths = uniqBy(this.selectedRows, 'save_path');

    const oldPath = savePaths.length > 1 ? '' : savePaths[0].save_path
    const v = await this.asyncShowDialog({
      title: tr('title.set_location'),
      text: '',
      type: DialogType.Input,
      value: oldPath,
    });

    if (!v) {
      return;
    }

    this.showSnackBar({text: tr('label.moving')});

    try {
      await api.setTorrentLocation(this.selectedHashes, v);
    } catch (e) {
      this.showSnackBar({text: e});
      return;
    }

    this.showSnackBar({text: tr('label.moved')});
  }

  setTorrentsCategory(category: string) {
    this.categoryToSet = category;
    this.toSetCategory = this.selectedRows;
  }

  editTracker() {
    if (this.hasSelected) {
      this.selectedRows = this.allTorrents;
    }
    this.toEditTracker = this.selectedRows;
  }

  @Watch('pageOptions', { deep: true})
  onPageOptionsChanged() {
    this.updateConfig({
      key: 'pageOptions',
      value: this.pageOptions,
    })
  }

  @Watch('filter')
  onFilterChanged() {
    this.selectedRows = []
  }

  @Watch('torrents')
  onTorrentsChanged(v: Torrent[]) {
    if (!this.hasSelected) {
      return;
    }

    const torrentHashs = v.map(t => t.hash);
    const toRemove = difference(this.selectedHashes, torrentHashs);
    if (!toRemove) {
      return;
    }

    this.selectedRows = this.selectedRows.filter(r => !toRemove.includes(r.hash));
  }
}
